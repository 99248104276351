
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
/* latin-ext */
//@font-face {
//  font-family: 'Raleway';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Raleway Light'), local('Raleway-Light'), url("../fonts/Raleway/Raleway-Light.ttf") format('woff2');
//  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Raleway';
//  font-style: normal;
//  font-weight: 300;
//  src: local('Raleway Light'), local('Raleway-Light'), url("../fonts/Raleway/Raleway-Light.ttf") format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Raleway';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Raleway'), local('Raleway-Regular'), url("../fonts/Raleway/Raleway-Regular.ttf") format('woff2');
//  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
///* latin */
//@font-face {
//  font-family: 'Raleway';
//  font-style: normal;
//  font-weight: 400;
//  src: local('Raleway'), local('Raleway-Regular'), url("../fonts/Raleway/Raleway-Regular.ttf") format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//}
///* latin-ext */
//@font-face {
//  font-family: 'Raleway';
//  font-style: normal;
//  font-weight: 600;
//  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url("../fonts/Raleway/Raleway-SemiBold.ttf") format('woff2');
//  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url("../fonts/Raleway/Raleway-SemiBold.ttf") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Variables
@import "variables";

// Bootstrap
//@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap";

@import "bootstrap-select/sass/bootstrap-select.scss";

@import "bootstrap-icons";

.equal, .equal > div[class*='col-'] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.equal .panel {
  flex:1 0 100%;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.etiquette-pdf
{
  font-size: 3.8em;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}